<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the reaction and its rate constant that are shown below:</p>

      <p class="mb-3 pl-4">
        <stemble-latex content="$\ce{A -> products} \hspace{0.413cm} \text{k}~=~$" />
        <number-value :value="rateConstant" unit="\text{M}^{-1}\text{s}^{-1}" />
      </p>

      <p class="mb-3">
        a) If the concentration of <chemical-latex content="A" /> is
        <number-value :value="finalConc" unit="\text{M}" /> after
        <number-value :value="reactionTime" unit="\text{s,}" /> what was the original concentration
        of <chemical-latex content="A," /> i.e. <chemical-latex content="[A]_0?" />
      </p>

      <calculation-input
        v-model="inputs.initialConc"
        prepend-text="$\ce{[A]_0}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
      <br />

      <p class="mb-3">b) What is the half-life for the reaction?</p>

      <calculation-input
        v-model="inputs.halfLife"
        prepend-text="$\text{t}_{1/2}:$"
        append-text="$\text{s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question179',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        initialConc: null,
        halfLife: null,
      },
    };
  },
  computed: {
    reactionTime() {
      return this.taskState.getValueBySymbol('reactionTime').content;
    },
    finalConc() {
      return this.taskState.getValueBySymbol('finalConc').content;
    },
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
  },
};
</script>
